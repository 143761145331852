.pdfcard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding-top: 20px;
  .pdf-card {
    border: 1px solid gray;
    padding: 100px;
    cursor: pointer;
  }
}

.pdfcard-container {
  padding: 16px;
  border-bottom: 1px solid #dddddd;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
  gap: 16px;
  .border {
    border: 1px solid #dddddd;
    width: 100%;

    .backgrund-icon {
      background: #f5f5f5;
      height: 196px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        height: 100px;
      }
    }
    .pdfcard-container-para {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding: 12px;
      .delete-icon {
        cursor: pointer;
        padding: 8px;
      }
    }
    .pdfcard-createcontainer-para {
      display: flex;
      align-items: center;
      padding: 12px;
    }
  }
}
