.pdf-section {
  height: 100vh;
  .pdf-header {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    justify-content: space-between;
    padding: 24px;
  }
  .pdf-wrapp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 0px 20px 20px 20px;
    @media (max-width: 780px) {
      grid-template-columns: 1fr;
    }
    .pdf-display-area {
      border: 1px solid #000;
      background: #eee;
      padding: 24px;
      height: 630px;
      overflow-y: scroll;
    }
    .textarea {
      textarea {
        padding: 24px;
        height: 630px;
        width: 100%;
      }
    }
  }
}
