.company-header {
  @media (min-width: 992px) {
    display: none;
  }
  .media-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding: 12px 16px;
    .media-logo {
      img {
        width: 150px;
      }
    }
    .menu {
      cursor: pointer;
    }
  }
  .sidebar-backdrop {
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 90;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .bar {
      position: fixed;
      width: 300px;
      height: 100vh;
      right: 0;
      top: 0px;
      background: #fff;
      backdrop-filter: blur(16px);
      z-index: 1000;
    }
  }
  .header-side-bar {
    padding: 32px;
    position: relative;
    .close-icon {
      cursor: pointer;
      width: 22px;
      position: absolute;
      right: 32px;
      top: 32px;

      img {
        width: 22px;
      }
    }
    .profile-content {
      margin-top: 32px;
      .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 18px;
          font-weight: 300;
          color: #fff;
          background-color: var(--yellow);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        cursor: pointer;
      }
      h6 {
        margin-top: 16px;
        font-size: 14px;
        text-align: center;
      }
      p {
        font-size: 12px;
        color: var(--primary-gray);
        margin-top: 4px;
        text-align: center;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 14px;
        padding: 8px 32px;
        width: 100%;
        margin-top: 16px;
        background: #ee5050;
        svg {
          margin-top: 3px;
        }
      }
    }

    .company-drop-down-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      margin-top: 36px;
      margin-bottom: 12px;
      cursor: pointer;
      &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      .media-building {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        p {
          font-size: 14px;
        }
      }
    }
    .company-name {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      overflow-x: hidden;
      overflow-y: scroll;
      gap: 16px;
      height: 350px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 22px;
        background: #dddddd;
      }
      .link-tag {
        margin: 16px 0;
        p {
          font-size: 14px;
        }
        .link {
          a {
            &:hover {
              color: var(--yellow);
            }
          }
        }
      }
    }
  }
}
