@font-face {
  font-family: "Gilroy-Bold";
  src: url("../src/assets/fonts/Gilroy-Bold.ttf");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("../src/assets/fonts/Gilroy-Medium.ttf");
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../src/assets/fonts/Gilroy-SemiBold.ttf");
}
@font-face {
  font-family: "Gilroy-Light";
  src: url("../src/assets/fonts/Gilroy-Light.ttf");
}

:root {
  --font-bold: "Gilroy-Bold";
  --font-medium: "Gilroy-Medium";
  --font-semiBold: "Gilroy-SemiBold";
  --font-light: "Gilroy-Light";
  --white: #fff;
  --primary-gray: #9b9899;
  --secondary-gray: #aaaaaa;
  --text-primary: #373435;
  --text-secondary: #9b9899;
  --yellow: #eec900;
  --gray: #777777;
  --border: #dddddd;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-medium);
}
html {
  scroll-behavior: smooth;
}
body {
  color: var(--text-primary);
}

.mx {
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: var(--text-primary);
}
button:disabled {
  cursor: no-drop;
}
.flex-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drop-down-icon.rotate {
  transform: rotate(-180deg);
  transition: 0.2s ease-in-out;
}
table,
tr,
td,
th {
  border: 2px solid #555;
}

table {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #555;
}

.errorMsg {
  color: red;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 12px;
  text-transform: capitalize;
}
