.btn {
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}
.btn-primary {
  padding: 12px 32px;
  display: flex;
  gap: 4px;
  font-size: 14px;
  background: var(--text-primary);
  color: var(--white);
  transition: all 0.3s linear;
  @media (max-width: 425px) {
    padding: 12px 22px;
    font-size: 12px;
  }
  &:hover {
    background: var(--yellow);
  }
}

.btn-register {
  padding: 12px 32px;
  font-size: 14px;
  background: #eec900;
  color: var(--white);
  transition: all 0.3s linear;
  &:hover {
    background: var(--text-primary);
  }

  @media (max-width: 425px) {
    padding: 12px 18px;
    font-size: 12px;
  }
}
.btn-secondary {
  padding: 12px 32px;
  background: transparent;
  border: 1px solid var(--text-primary);
  font-size: 14px;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
  color: #1e1e1e;
  transition: all 0.3s linear;
  &:hover {
    border: 1px solid var(--yellow);
  }

  @media (max-width: 425px) {
    padding: 12px 18px;
    font-size: 12px;
  }
}
.btn-add {
  padding: 12px 42px;
  background: #dddddd;
}
