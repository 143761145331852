.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
  }
  .modal-box {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    padding: 20px;
    width: 320px;
    height: 250px;
    overflow-y: auto;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    transition: 0.5s;
    animation-delay: 0.5s;
    cursor: auto;
    @media (max-width: 425px) {
      height: 85%;
    }
    .custom-name {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      input {
        width: 100%;
        padding: 8px;
        border: none;
        outline: none;
        border-bottom: 1px solid var(--yellow);
        background: transparent;
      }
    }
    .modal-close-btn {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      transition: transform 250ms ease-in-out;
      @media (max-width: 580px) {
        top: 38px;
      }
      &:hover {
        transform: rotate(180deg);
      }
      img {
        width: 25px;
        @media (max-width: 580px) {
          width: 18px;
        }
      }
    }
    .modal-title {
      font-size: 30px;
    }
    .modal-content {
      margin-top: 30px;
      font-size: 16px;
    }
  }
}
